(function ($) {
  var searchWindow = document.getElementById("search-window");
  var closeSearch = document.getElementById("search-popup__close-btn");
  document.addEventListener("click", function (e) {
    var searchBtn = e.target.closest(".navbar__search-btn");
    if (searchBtn) {
      searchWindow.classList.add("popup--open");
      searchWindow.querySelector(".red-square").classList.add("animated");
      setTimeout(function () {
        document.getElementById("search-input-window-ts-control").focus();
      });
    }
  });
  closeSearch.addEventListener("click", function () {
    searchWindow.classList.remove("popup--open");
  });
  var swiperKeywords = new VTX_Sliders(".search-bar__request__slider", {
    slidesPerView: "auto",
    spaceBetween: 20
  });
})(jQuery);